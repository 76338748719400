const navbar = {
    navbar: {
        "/rotation": "Heavy Rotation",
        "/apps": "Frp Apps",
        "/labels": "Labels",
        "/frpTv": "FRP TV",
        "/tracks/rank": "Billboard 100",
        "/tracks": "Music",
        "/home": "Home",
        "/contactUs": "Contact",
        "/aboutUs": "About",
        "/splash": "Splash",
        "/profile": "Profile",
        "/menuProfile": "My Profile",
        "/menuSubscription": "Subscription",
        "/menuBilling": "Billing History",
        "/menuLogout": "Log out",
        "/ai-assistant": "AI Assistant",
        downloadList: {
            singular: "track",
            plural: "tracks"
        }
    }
};

export default navbar;
