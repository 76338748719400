import React, { useContext, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import routerDecorator from "components/helpers/withRouter";
import { AuthContext } from "context/AuthContext";
import { ContentContext } from "context/ContentContext";
import DesktopNavbar from "components/main/NavbarComponents/DesktopNavbar";
import MobileNavbar from "components/main/NavbarComponents/MobileNavbar";

const LINKS = ["/home", "/apps", "/rotation", "/ai-assistant", "/contactUs"];
const LINKS_FOR_BASE_USER = [
    "/home",
    "/tracks",
    "/apps",
    "/rotation",
    "/ai-assistant",
    "/contactUs"
];

Navbar.propTypes = {
    location: PropTypes.object.isRequired
};

function Navbar(props) {
    const { opacity, isHome } = props; 
    
    const { user } = useContext(AuthContext);
    const { getDownloads } = useContext(ContentContext);
    const [showMenu, setShowMenu] = useState(false);
    const [smallMenu, setSmallMenu] = useState(window.innerWidth < 600);
    const [searchQuery, setSearchQuery] = useState("");
    const userMenu = useRef(null);
    useEffect(() => {
        if (props.location.pathname === "/search") {
            let query = new URLSearchParams(props.location.search);
            const name = query.get("name");
            if (name === "live-n-livin") {
                setSearchQuery("Sean Paul");
            }
        }
    }, [props.location]);
    useEffect(() => {
        document.body.onclick = e => {
            (userMenu.current && userMenu.current.contains(e.target)) ||
                setShowMenu(false);
        };
    }, [showMenu]);

    useEffect(() => {
        if (user) getDownloads();
    }, [user]);

    useEffect(() => {
        const listener = () => setSmallMenu(window.innerWidth < 600);
        window.addEventListener("resize", listener);
        return window.removeEventListener("scroll", listener);
    }, []);

    const links = user ? LINKS_FOR_BASE_USER : LINKS;

    return smallMenu ? (
        <MobileNavbar
            links={links}
            userMenu={userMenu}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
        />
    ) : (
        <DesktopNavbar
            opacity={opacity}
            links={links}
            setShowMenu={setShowMenu}
            showMenu={showMenu}
            userMenu={userMenu}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            isHome={isHome}
        />
    );
}

export default routerDecorator(Navbar);
