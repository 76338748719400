import React from 'react';
import AIAssistant from '../AIAssistant';
import styles from './AIAssistantPage.module.scss';

const AIAssistantPage = () => {
    return (
        <div className={`${styles.aiAssistantPage} ${styles.aiAssistantPage_advanced}`}>
            <AIAssistant />
        </div>
    );
};

export default AIAssistantPage; 