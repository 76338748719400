import React from "react";
import { Route, Redirect } from "react-router-dom";
import ProfilePage from "components/pages/BaseUser/ProfilePage";
import RotationPage from "../components/pages/RotationPage";
import ArtistPage from "../components/pages/ArtistPage";
import SplashPage from "../components/pages/SplashPage";
import newHome from "components/pages/newHome";
import spin from "components/pages/Spin";
import frpApp from "components/pages/FrpApp";
import AppsPage from "components/pages/AppsPage";
import AIAssistantPage from "../components/pages/AIAssistantPage";

export default function UnpaidUserRoutes() {
    return [
        <Route exact path="/profile/:type/:subtype?" component={ProfilePage} />,
        <Route exact path="/home" component={newHome} />,
        <Route exact path="/rotation" component={RotationPage} />,
        <Route exact path="/rotation/artist/:id" component={ArtistPage} />,
        <Route exact path="/" component={SplashPage} />,
        <Route exact path="/spins-guide" component={ spin } />,
        <Route exact path="/app-guide" component={ frpApp } />,
        <Route exact path="/apps/:type?" component={AppsPage} />,
        <Route exact path="/ai-assistant" component={AIAssistantPage} />,
        <Redirect to="/profile/subscription/unpaid" />
    ];
}

UnpaidUserRoutes.propTypes = {};
